import React, { useEffect } from "react"
import Link from "gatsby-link"
import Background from "@app-components/utils/background"
import Layout from "@app-components/layout/"

const Login = () => {
  const loginURL = "https://accounts.cinesend.com/login"
  useEffect(() => {
    window.location.href = loginURL
  }, [loginURL])
  return (
    <Layout getStartedButtons={["demo", "get-started"]} page="login">
      <Background tagName="section" className="bg-cover bg-no-repeat bg-left center white" type="blue-single">
        <div className="flex items-center justify-center" style={{ height: "100vh" }}>
          <div>
            <h1>CineSend Login</h1>
            <p>Hi there, click the button below to log in to CineSend!</p>
            <Link className="mt2 inline-block" to="/">
              <button
                className="white"
                onClick={() => {
                  window.location.href = loginURL
                }}>
                Log In
              </button>
            </Link>
          </div>
        </div>
      </Background>
    </Layout>
  )
}

export default Login
